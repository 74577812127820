@import "./theme.scss";
@import "~node_modules/moment-timezone-picker/assets/moment-timezone-picker.theme";
@import "~@ng-select/ng-select/themes/material.theme.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  min-height: 100vh;
}
svg{
  width: 60px;
  height: 60px;
}

.mat-gray-color {
  background-color: mat-color($gray-color) !important;
}


.mat-header-row,
.mat-paginator {
  // background: mat-color($pn-primary) !important;
  button,
  th,
  mat-header-cell,
  .mat-sort-header-indicator,
  .mat-sort-header-stem {
    color: white !important;
  }
}

.mat-header-row {
  border-width: 0;
  th {
    border-bottom-width: 0 !important;
  }
  background: white !important; //mat-color($pn-primary)!important;  // fallback for old browsers
  //colore del testo dell'input di ricerca nell'header delle grid
  th,
  mat-header-cell,
  .mat-sort-header-indicator,
  .mat-sort-header-stem {
    color: black !important;
  }
  button {
    color: white !important;
  }
}

.titles-header-row {
  background: white !important;
  // button,
  // th,
  // mat-header-cell,
  // .mat-sort-header-indicator,
  // .mat-sort-header-stem {
  //   color: mat-color($pn-primary) !important;
  // }
}

.mat-paginator {
  color: white !important;

  .mat-select-value-text,
  .mat-select-arrow {
    color: white !important;
  }
}

//evidenziazione rigo grid selezionato
.highlight td,
.highlight mat-cell {
  background-color: mat-color($gray-color, 50) !important;
}

.loading-shade {
  position: absolute !important;
  left: 46%;
  top: 35%;
  margin: 0px auto;
  z-index: 99999;
}

.grid-detail-row-content,
.fieldsetBorder {
  // border: 2px solid mat-color($pn-primary) !important;
  border-radius: 5px;
}

.tooltip-lines {
  white-space: pre-line; //va a capo quando trova interruzione di linea (\n)
  line-height: 20px;
}

// mat-card-header {
//   color: mat-color($pn-primary);
// }

// mat-dialog-container {
//   background-color: mat-color($pn-primary, 100) !important;
// }

.rotate-icon {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.readonly-field .mat-form-field-underline {
  display: none !important;
}

// serve per mettere i paginator che hanno questa classe in fondo fissi
.sticky-paginator {
  position: sticky !important;
  position: -webkit-sticky !important; /* For macOS/iOS Safari */
  bottom: 0 !important;
}

.d-none {
  display: none;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// mat-label {
//   color: mat-color($pn-primary);
// }

// .filtro-grid button {
//   color: mat-color($pn-primary) !important;
// }

// .filtro-grid .field-row mat-icon {
//   color: mat-color($pn-warn) !important;
// }

.radio-orizzontale .mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.radio-verticale {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  .mat-radio-button {
    margin: 5px;
  }
}


.list-option,
.list-option.sessione-option-detail,
.list-option .relazione-option-detail {
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 8px;
  min-width: 20em;
  display: flex;
  background-color: white;
}

.list-option {
  background-color: mat-color($pn-primary, 50);
}
.list-option-odd {
  background-color: mat-color($pn-primary, 50);
}

.list-option-even {
  background-color: mat-color($pn-primary, 100);
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

// h1 {
//   margin-bottom: 0 !important;
// }

.sessioni-list {
  .mat-list-item {
    height: 95px !important;
  }
  .mat-list-text {
    height: 100% !important;
    padding-right: 0 !important;
    //background-color: #f0f0f0;
  }
  .mat-list-item-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.relazioni-list {
  .mat-list-item {
    height: 85px !important;
  }
  .mat-list-text {
    height: 100% !important;
    padding-right: 0 !important;
  }
  .mat-list-item-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.persone-list .mat-list-item {
  height: auto !important;
}

.tag-list {
  .mat-list-text {
    padding-right: 0 !important;
  }
}

.main-padding {
  padding: 10px !important;
}

.titolo {
  padding: 10px 10px 0px 10px;
  text-align: left;

  h1,
  h2 {
    line-height: 1.3em;
  }
}

/*
.mat-list-text {
  font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 200)));
  letter-spacing: -0.02rem;
  line-height: 1.2em;
 }
*/

// si impone di avere massimo due righe
// se il testo le supera, vengono messi i tre puntini di sospensione
.maxRows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

}

.avatar {
  // margin: 0 auto;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
  //   background-image: "url(assets/img/account_circle.svg)";
  background-size: cover;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.7;
}

.mat-tab-label-content {
  width: 100%;
}
.main-virtual-viewport{
  height: 100vh;
}
