$toolbar-height: 7vh;
$toolbar-min-height: 70px;

$container-max-height: 98vh - $toolbar-height;

@import "~@angular/material/theming";

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-gray: (
  50: #f9f9f9,
  100: #f0f0f0,
  200: #e6e6e6,
  300: #dcdcdc,
  400: #d5d5d5,
  500: #cdcdcd,
  600: #c8c8c8,
  700: #c1c1c1,
  800: #bababa,
  900: #aeaeae,
  A100: #ffffff,
  A200: #ffffff,
  A400: #fffdfd,
  A700: #ffe4e4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
//ogni livello ha: font-size, line-height e font-weight
$pn-typography: mat-typography-config(
  $font-family: "Ubuntu, sans-serif",
  $display-4: mat-typography-level(112px, 112px, 800),
  $display-3: mat-typography-level(56px, 56px, 800),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 800),
  /*h1*/ /*parametri: font-size, line-height e font-weight*/ $title:
    mat-typography-level(20px, 32px, 800),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(16px, 24px, 800),
  /*base text bold*/ $body-1: mat-typography-level(16px, 20px, 400),
  /*base text*/ $caption: mat-typography-level(12px, 16px, 400),
  $button: mat-typography-level(16px, 14px, 800),
  $input: mat-typography-level(14px, 1, 400),
);
/*per dimuire altezza righe grid vedere .mat-row, .mat-footer-row in shared-style-list.scss*/

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($pn-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($pn-typography);

@include mat-core($pn-typography);

$pn-accent: mat-palette($md-accent, 500, 300, 800);
$pn-primary: mat-palette($md-primary, 500, 300, 800);
$pn-warn: mat-palette($md-accent, 500, 300, 900);
$pn-theme: mat-light-theme($pn-primary, $pn-accent, $pn-warn);

@include angular-material-theme($pn-theme);

$gray-color: mat-palette($md-gray, 500, 300, 800);
